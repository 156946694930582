.userCard ion-avatar,
.userCard ion-skeleton-text.avatar {
  width: var(--avatar-size-large);
  height: var(--avatar-size-large);
  margin: 0 auto;
  position: relative;
  font-size: var(--font-size-xl);
}
.userCard ion-skeleton-text.avatar {
  border-radius: 50%;
}
