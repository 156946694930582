.ion-page.pageLoading {
  --background-color: transparent;
}
.ion-page.pageLoading .loadingContainer {
  width: 200px;
  height: 200px;
  text-align: center;
  position: absolute;
  left: calc(50% - 100px);
  top: 150px;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.25s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: var(--smooth-ease);
}
.ion-page.pageLoading .loadingContainer p {
  position: absolute;
  top: 20px;
  left: 0;
  margin: 0;
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
