@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500&display=swap');
/* OVERRIDE some colors for the landing page. */
#landingPage {
  --background-color: #fff;

  --ion-color-primary: #3b7689;
  --ion-color-primary-rgb: 59, 118, 137;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #346879;
  --ion-color-primary-tint: #4f8495;

  --ion-color-secondary: #a2c2be;
  --ion-color-secondary-rgb: 162, 194, 190;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #8faba7;
  --ion-color-secondary-tint: #cee1de;
}
#landingPage h1 {
  color: #101828;
  font-size: 60px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -1.2px;
}
#landingPage h2 {
  color: #101828;
  font-size: 48px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.96px;
}
#landingPage h3 {
  color: #101828;
  text-align: center;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
#landingPage h4 {
  color: #1d2939;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
#landingPage p {
  color: #475467;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
#landingPage p.subheader {
  font-size: 20px;
  line-height: 30px;
  margin: 24px 0;
}
#landingPage p.subtitle {
  color: #475467;
  text-align: center;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
#landingPage ion-input.rounded {
  height: 44px;
}
#landingPage ion-note {
  color: #475467;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
#landingPage a,
#landingPage ion-router-link {
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

.landingIconContainer {
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background-color: var(--ion-color-secondary-tint);
  position: relative;
}
.landingIconContainer .landingIconContainerInner {
  position: absolute;
  width: 50px;
  height: 50px;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  border-radius: 50%;
  background-color: var(--ion-color-secondary);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landingIconContainer img {
  width: 28px;
  height: 28px;
}
.landingIconContainer svg {
  color: black;
  width: 28px;
  height: 28px;
}

#landingPage #heroContainer {
  width: 520px;
  max-width: 100%;
  height: 640px;
  position: relative;
  margin-left: auto;
}
#landingPage #heroContainer .hero {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#landingPage #heroContainer .pattern {
  position: absolute;
  top: 0;
  right: -4%;
  width: 51%;
  height: 100%;
  object-fit: contain;
  object-position: center bottom;
}

#landingPage #heroContainer .orderStat {
  width: 100%;
  max-width: 250px;
  position: absolute;
}
#landingPage #heroContainer .orderStat ion-card {
  --background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
#landingPage #heroContainer .orderStat.orders {
  top: calc(50% + 60px);
  left: -10px;
}
/* #landingPage #heroContainer .orderStat.spend {
  top: calc(50% - 10px);
  left: 20%;
} */

#landingPage ion-button {
  --padding-start: 18px;
  --padding-end: 18px;
}
#landingPage ion-button[expand='full'] {
  --border-radius: 10px;
}
#landingPage ion-header ion-toolbar {
  --border-style: none;
}
#landingPage ion-content > ion-grid {
  padding-left: 0;
  padding-right: 0;
  padding-top: 75px;
  padding-bottom: 75px;
}
#landingPage .container,
#landingPage ion-content > ion-grid > ion-row {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

#landingPage .dark-bg {
  background-color: #1d2939;
  color: #fff;
}
#landingPage .dark-bg p,
#landingPage .dark-bg h1,
#landingPage .dark-bg h2,
#landingPage .dark-bg h3,
#landingPage .dark-bg h4,
#landingPage .dark-bg h5,
#landingPage .dark-bg h6 {
  color: #fff;
}

#landingPage .light-bg {
  background: var(--ion-color-secondary-tint);
}

#faqs {
  max-width: 768px;
  margin: 0 auto;
}
#faqs ion-accordion {
  border-bottom: 1px solid #eaecf0;
}
#faqs ion-label {
  color: #101828;
  /* Text lg/Medium */
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  white-space: normal;
}
#faqs ion-item {
  --border-style: none;
  --background: var(--background-color);
}
#faqs p {
  margin: 0;
}

#landingPage #laptopContainer {
  border-radius: 16px;
  width: 768px !important;
  height: 560px;
  max-width: none;
  background-color: var(--ion-color-secondary-tint);
  padding: 56px 40px;
}

#landingPage #laptopContainer .laptop {
  position: relative;
  width: 753px;
  height: 445px;
  background-repeat: no-repeat;
  background-position: top left;
}
#landingPage #laptopContainer .screen {
  width: 723px;
  height: 392px;
  background-repeat: no-repeat;
  background-position: top left;
  position: absolute;
  top: 14px;
  left: 72px;
  border-radius: 8px;
}

#landingPage ion-row.copyrightRow {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #475467;
}
#landingPage ion-row.copyrightRow .copyright {
  width: 100%;
  text-align: center;
  margin: 0;
}

#landingPage ion-row.copyrightRow ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: var(--app-spacing);
}
#landingPage ion-row.copyrightRow ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: var(--app-spacing);
}
#landingPage ion-row.copyrightRow ul li a {
  color: #fff;
}

@media screen and (min-width: 576px) {
  #landingPage ion-row.copyrightRow ul {
    margin: 0;
    justify-content: flex-end;
  }
  #landingPage ion-row.copyrightRow .copyright {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  #landingPage #heroContainer {
    margin-top: 50px;
  }
}
@media screen and (max-width: 600px) {
  #landingPage #heroContainer {
    height: auto;
    width: 100%;
  }
  #landingPage #heroContainer .hero {
    width: 100%;
    height: auto;
  }
  #landingPage ion-content > ion-grid {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
