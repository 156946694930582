.slideHeader {
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  position: relative;
  margin: 0 auto;
}
.slideHeader .step {
  --step-color: #ccc;
  color: var(--step-color);
  transition: all 0.4s ease-out;
  text-align: center;
  background-color: var(
    --ion-card-background,
    var(--ion-item-background, var(--ion-background-color, #fff))
  );
  user-select: none;
}
.slideHeader .step .number {
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--step-color);
  background-color: transparent;
  transition: all 0.2s ease-out;
}
.slideHeader .step.active {
  --step-color: var(--ion-color-primary);
}
.slideHeader .step.complete .number {
  background-color: var(--ion-color-primary);
  color: #fff;
}
.slideHeader .step:not(:first-child)::before,
.slideHeader .step:not(:first-child)::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 0px;
  border-bottom: 2px solid transparent;
  top: 20px;
  left: calc(50% - 82px);
  transition: width 0.4s ease-out;
}
.slideHeader .step:not(:first-child)::before {
  width: 50%;
  border-bottom-color: var(--background-color);
}
.slideHeader .step:not(:first-child)::after {
  border-bottom-color: var(--ion-color-primary);
}
.slideHeader .step.active:not(:first-child)::after {
  width: 50%;
}
