.ion-page.suppliers .divider.featured {
  --text-divider-background: var(--background-color);
}

#suppliersFilterPopover {
  --width: 350px;
}

.ion-page.suppliers #supplierSearch {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 38px;
}
