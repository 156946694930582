/*
  Custom stylesheet for Inline Manual
    Detailed info on CSS styling can be found here:
    https://support.inlinemanual.com/support/solutions/articles/80000983335-how-to-customize-inline-manual-with-css
*/

/*
//////////////////////////////////////////////////////////////////////
/// Begin Panel (open widget) styles
*/
.inmplayer-panel {
  color: var(--ion-text-color);
  font-family: var(--ion-font-family);
  font-size: var(--font-size-small);
}
.inmplayer-active {
  background: var(--ion-color-light);
}
.inmplayer-panel-header {
  font-size: var(--font-size-large);
  font-weight: 500;
}
.inmplayer-panel-search input {
  background: var(--ion-color-base);
  border-radius: var(--border-radius);
  font-size: var(--font-size-small);
  border: 1px solid #777;
  padding: 5px;
  padding-left: 10px;
  color: #000;
}
.inmplayer-panel-search input:focus-visible {
  outline-offset: 0px;
}
.inmplayer-panel-search button {
  background: var(--ion-color-primary);
  padding: 5px;
  border-radius: 5px;
}
.inmplayer-panel-search a {
  padding: 5px;
  border-radius: 5px;
  text-decoration: none;
  color: var(--ion-color-medium);
  border: 1px solid var(--ion-color-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.inmplayer-panel-close-button,
.inmplayer-panel-back-button {
  line-height: 36px;
}
.inmplayer-panel-close-button {
  font-size: 30px;
}
.inmplayer-panel-back-button {
  font-size: 25px;
}
.inmplayer-panel-body {
}
.inmplayer-list-item {
}
.inmplayer-list-item:hover,
.inmplayer-list-subfolder .inmplayer-list-item:hover {
  color: var(--ion-color-primary);
}
.inmplayer-highlight {
}
.inmplayer-panel-footer {
}
.inmplayer-panel-custom-footer {
}
.inmplayer-panel-footer-branding {
}
/* 
\\\ End Panel (open widget) styles
\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
*/

/*
//////////////////////////////////////////////////////////////////////
/// Begin Popover (step) styles
*/
.inmplayer-popover {
}
.inmplayer-popover-wrapper {
}
.inmplayer-popover-content {
}
.inmplayer-popover-title {
}
.inmplayer-popover-inner-content {
}
.inmplayer-popover-buttons {
}
.inmplayer-popover-button-previous {
}
.inmplayer-popover-button-next {
}
.inmplayer-popover-button-end {
}
.inmplayer-popover-progress {
}
.inmplayer-popover-scroll_message {
}
.inmplayer-popover-orphan {
}
.inmplayer-popover-pointer-holder {
}
.inmplayer-popover-pointer-shape {
}
/* 
\\\ End Popover (step) styles
\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
*/
