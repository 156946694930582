#appHeader ion-buttons {
  align-self: center;
}
#appHeader ion-toolbar {
  height: var(--toolbar-height);
}

#desktopToolbar ion-searchbar {
  display: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  max-width: calc(100% - 100px);
  margin: 0 auto;
}

#appHeader ion-searchbar.ios input.searchbar-input.sc-ion-searchbar-ios {
  border: none;
}

#userInfo {
  position: relative;
  height: var(--toolbar-height);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  border-radius: 8px;
  overflow: hidden;
  pointer-events: all;
  user-select: none;
  max-width: 250px;
}
#userInfo .label {
  text-align: left;
  margin-right: 16px;
  font-size: var(--font-size-default);
  width: calc(100% - 16px - var(--avatar-size));
}
#userInfo .label p {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: var(--font-size-small);
}
#userInfo ion-avatar {
  width: var(--avatar-size);
  height: var(--avatar-size);
}

#userInfoPopover {
  --width: 300px;
}

#userInfoPopover .divider {
  margin: var(--app-spacing-small) 0;
}

#userInfoPopover ion-avatar {
  width: 50px;
  height: 50px;
}

@media (max-width: 575px) {
  #userInfo {
    padding: 0;
    padding-left: var(--app-spacing-small);
    padding-right: var(--app-spacing-xs);
  }

  /* only applies to the supplier portal, where we don't have multiple toolbars */
  ion-app.supplier #desktopToolbar {
    --padding-top: 0;
    --padding-bottom: 0;
  }
}

@media (min-width: 576px) {
  #desktopToolbar ion-searchbar {
    display: flex;
  }
  #desktopToolbar {
    --padding-top: 0;
    --padding-bottom: 0;
  }
  #mobileToolbar {
    display: none;
  }
}
