ion-card.centerCard {
  max-width: 500px;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
}
.centerCard ion-card-title {
  padding-bottom: 0;
  font-size: 20px;
  font-weight: 400;
}
.centerCard ion-card-content {
  padding-top: 0;
}
.centerCard ion-card-content .formContainer {
  max-width: 400px;
  margin: 0 auto;
}
