#settingSupplierModal {
  --width: 900px;
}
#settingSupplierModal .logoContainer {
  border-bottom: 1px solid var(--border-color);
}

#settingSuppliersFilterPopover {
  --width: 275px;
}
