/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #64C5B7;
  --ion-color-primary-rgb: 100,197,183;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #58ada1;
  --ion-color-primary-tint: #74cbbe;
  
  --ion-color-secondary: #1F4D6E;
	--ion-color-secondary-rgb: 31,77,110;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #1b4461;
	--ion-color-secondary-tint: #355f7d;
		/* Trulla Blue */
	/* --ion-color-secondary: #009efb;
	--ion-color-secondary-rgb: 0,158,251;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #008bdd;
	--ion-color-secondary-tint: #1aa8fb; */

  --ion-color-tertiary: #D5C947;
  --ion-color-tertiary-rgb: 213,201,71;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #bbb13e;
  --ion-color-tertiary-tint: #d9ce59;

	--ion-color-success: #55ce63;
	--ion-color-success-rgb: 85,206,99;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #4bb557;
	--ion-color-success-tint: #66d373;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}


