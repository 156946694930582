.accountChip {
  border-radius: 16px;
  -webkit-font-smoothing: antialiased;
  margin: 4px;
  padding: 5px 10px;
  background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.12);
  color: var(--ion-text-color);
  font-size: 14px;
  line-height: 1.2;
  width: auto;
  max-width: 160px;
}

.accountChip .accountNumber,
.accountChip .accountDescription {
  font-size: var(--font-size-xs);
  margin: 0;
}

.accountChip .accountDescription {
  color: black;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
