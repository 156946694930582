.userProfile ion-card {
  max-width: 500px;
  margin: 0 auto;
}
.userProfile ion-avatar,
.userProfile ion-skeleton-text.avatar {
  width: var(--avatar-size-large);
  height: var(--avatar-size-large);
  margin: 0 auto;
  position: relative;
  font-size: var(--font-size-xl);
}
.userProfile ion-skeleton-text.avatar {
  border-radius: 50%;
}

.userProfile h1.name {
  position: relative;
  padding: 0 var(--app-spacing);
}
.userProfile h1.name ion-button {
  position: absolute;
  margin-left: var(--app-spacing-small);
}
