.divider {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: var(--border-color);
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.divider.light {
  --text-divider-background: var(--background-color);
}
.divider.ion-no-margin {
  margin: 0;
}
.divider p {
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 16px;
  background-color: var(--text-divider-background, #fff);
}
