ion-card.homeStat h2 {
  font-size: var(--font-size-xl);
  margin-bottom: 16px;
  color: black;
}

h2.sectionTitle {
  display: inline-block;
  color: black;
  font-weight: 400;
  font-size: var(--font-size-xl);
}

.ion-page.pageHome ion-card.supplierCard {
  width: 150px;
  margin: var(--app-spacing-xs);
}
