ion-popover#ordersFilterPopover {
  --width: 275px;
}
.ion-page.orders .cardStatsContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.ion-page.orders .cardStatsContainer > ion-card {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  margin: var(--app-spacing-xs);
  width: 100%;
}

@media screen and (min-width: 385px) {
  .ion-page.orders .cardStatsContainer > ion-card {
    width: calc(50% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 768px) {
  .ion-page.orders .cardStatsContainer > ion-card {
    width: calc(33% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 900px) {
  .ion-page.orders .cardStatsContainer > ion-card {
    width: calc(25% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 990px) and (max-width: 1199px) {
  .ion-page.orders .cardStatsContainer > ion-card {
    width: calc(20% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .ion-page.orders .cardStatsContainer > ion-card {
    width: calc(25% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 1300px) {
  .ion-page.orders .cardStatsContainer > ion-card {
    width: calc(20% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 1500px) {
  .ion-page.orders .cardStatsContainer > ion-card {
    width: calc(20% - var(--app-spacing-xs) * 2);
    max-width: 245px;
  }
}
/* .ion-page.orders .cardStats {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
} */
