.buttonBadgeWrapper {
  position: relative;
}
.buttonBadgeWrapper ion-button.button-has-icon-only + ion-badge {
  position: absolute;
  top: 0;
  left: calc(100% - 16px);
  font-size: 11px;
  font-weight: 400;
  pointer-events: none;
  user-select: none;
}
.buttonBadgeWrapper ion-button.button-has-icon-only + ion-badge.left {
  right: unset;
  left: 0;
}
.buttonBadgeWrapper ion-button.button-has-icon-only + ion-badge.bottom {
  top: unset;
  bottom: 5px;
}
