ion-breadcrumb.breadcrumb-active {
  color: black;
}

ion-breadcrumb.ion-activatable {
  --color: var(--ion-color-secondary);
}

ion-breadcrumbs ion-breadcrumb:last-child {
  font-weight: 400;
  max-width: 100%;
}

.breadcrumbs .breadcrumbTitleContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}
.breadcrumbs h3 {
  font-weight: 400;
  margin: 0;
  font-size: var(--font-size-xl);
  margin-bottom: 0;
  color: var(--ion-color-primary);
  line-height: 30px;
}

.breadcrumbTitleContainer .breadcrumbActions ion-button {
  margin: 0;
}
.breadcrumbTitleContainer .breadcrumbActions ion-button:not(:first-of-type) {
  margin-left: var(--app-spacing-small);
}

@media (min-width: 576px) {
  ion-breadcrumb:first-child {
    margin-left: -12px;
  }
}
@media (max-width: 575px) {
  .breadcrumbs h3 {
    margin-left: 12px;
  }
}
