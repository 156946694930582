ion-menu {
  --side-width: 240px;
  --side-min-width: 240px;
}

ion-menu .ios ion-accordion [slot='content'] ion-item ion-label {
  padding-left: 48px;
}
ion-menu ion-list-header.logoHeader {
  height: var(--toolbar-height);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
ion-menu ion-list {
  padding: 14px 0;
}

ion-menu ion-list:first-child {
  padding-top: 0;
}

ion-menu ion-list-header {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: var(--app-spacing-small);
  border-bottom: 1px solid var(--border-color);
  padding: 6px 16px 5px 16px;
}

ion-menu ion-item {
  border-left: 4px solid transparent;
  position: relative;
}

ion-menu ion-item.selected {
  color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}

ion-menu ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu ion-item:hover {
  --ion-text-color: var(--ion-color-primary);
}

ion-menu .svg-inline--fa {
  font-size: 1.2em;
  width: 1.2em;
  height: 1.2em;
}
